import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import gsap from 'gsap';

import { InView } from 'react-intersection-observer';
import { DateTime } from 'luxon';
import { css } from '@emotion/core';

import HeroText from '../components/hero-text';
import { HalfWidth, ContainerFlex, Container } from '../elements/layouts';
import { H2, H6, P } from '../elements/typography';
import { setMargin, setPadding } from '../elements/spacing';
import { renderContent, setColorMode } from '../utilities/functions';
import Footer from '../components/footer';
import Modal from '../components/modal';
import ContactForm from '../components/contact-form';

const CurrentTime = ({ city }) => {
  let timezone;
  switch (city) {
    case 'BLN':
      timezone = 'Europe/Berlin';
      break;
    case 'LND':
      timezone = 'Europe/London';
      break;
    case 'NYC':
      timezone = 'America/New_York';
      break;
    case 'LA':
      timezone = 'America/Los_Angeles';
      break;
    default:
      break;
  }

  const { hour, minute } = DateTime.local().setZone(timezone).c;
  const isOpen = hour >= 9 && hour <= 17;

  const formatNumber = num => (num <= 9 ? `0${num}` : num);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <H6>
        {formatNumber(hour)}:{formatNumber(minute)}
      </H6>
      <span
        css={css`
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background-color: ${isOpen ? '#08C50A' : '#E73C07'};
          margin-left: 8px;
          @media (min-width: 768px) {
            height: 13px;
            width: 13px;
          }
        `}
      ></span>
    </div>
  );
};

const Location = ({ city, address, email, phone, handleShowModal }) => {
  const handleReveal = (inView, entry) => {
    if (inView) {
      const tl = new gsap.timeline();
      const el = entry.target.firstElementChild;

      tl.to(el, {
        duration: 1.6,
        ease: 'power3.out',
        y: 0,
        opacity: 1,
      });
    }
  };

  return (
    <InView
      threshold={0.25}
      triggerOnce={true}
      onChange={(inView, entry) => handleReveal(inView, entry)}
    >
      <div
        css={css`
          border-top: 1px solid white;
          opacity: 0;
          transform: translateY(10%);
          ${setPadding(['top'], 'sm')}
          ${setMargin(['bottom'], 'lg')};
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            ${setMargin(['bottom'], 'md')}
          `}
        >
          <H2>{city}</H2>
          <CurrentTime city={city} />
        </div>
        <div
          css={css`
            ${setMargin(['bottom'], 'md')}
            max-width: 40%;
          `}
        >
          <H6 dangerouslySetInnerHTML={{ __html: address }} />
        </div>
        <div>
          <P
            css={css`
              text-transform: uppercase;
              margin-bottom: 16px;
              border-bottom: 1px solid white;
              display: inline-block;
              cursor: pointer;
              &:hover {
                border-bottom: 1px solid transparent;
              }
            `}
            onClick={handleShowModal}
          >
            Contact Us
          </P>
          <H6>{phone}</H6>
        </div>
      </div>
    </InView>
  );
};

const Contact = ({ data: { contacts }, location }) => {
  const [showModal, setShowModal] = useState(false);
  const { offices, content, headlineNode } = contacts;
  const { htmlAst } = headlineNode.childMarkdownRemark;

  useEffect(() => {
    setColorMode(location.pathname);
  }, [location.pathname]);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <HeroText headline={htmlAst} />
      <ContainerFlex>
        {offices &&
          offices.map(office => (
            <HalfWidth id={office.id} key={office.id}>
              <Location
                city={office.location}
                address={office.address}
                email={office.email}
                phone={office.phone}
                handleShowModal={handleShowModal}
              />
            </HalfWidth>
          ))}
      </ContainerFlex>
      <Modal show={showModal} handleCloseModal={handleCloseModal}>
        {showModal && <ContactForm />}
      </Modal>

      <Container>{renderContent(content)}</Container>
      <Footer pathname={location.pathname} />
    </>
  );
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contacts: datoCmsContact {
      id
      title
      headlineNode {
        childMarkdownRemark {
          htmlAst
        }
      }
      offices {
        address
        id
        email
        location
        phone
      }
      content {
        ... on DatoCmsImageGrid {
          id
          images {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsSingleImage {
          id
          alignment
          image {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsMailchimpForm {
          id
          embedCode
        }
        ... on DatoCmsSocialLink {
          id
          instagram
          twitter
          linkedin
        }
        ... on DatoCmsTextContent {
          id
          alignment
          width
          height
          textNode {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
      }
    }
  }
`;
