import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { css } from '@emotion/core';
import { useEffect } from 'react';

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  fullname: Yup.string().required('Required'),
  company: Yup.string().required('Required'),
  jobtitle: Yup.string().required('Required'),
  location: Yup.string().required('Required'),
  jobposition: Yup.string().required('Required'),
  industry: Yup.string().required('Required'),
  request: Yup.string().required('Required'),
});

const ContactForm = () => {
  const [serverState, setServerState] = useState();
  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg });
  };

  const handleOnSubmit = (values, actions) => {
    axios({
      method: 'POST',
      url: 'https://formspree.io/mjvaakdj',
      data: values,
    })
      .then(response => {
        actions.setSubmitting(false);
        actions.resetForm();
        handleServerResponse(true, 'Thanks!');
      })
      .catch(error => {
        actions.setSubmitting(false);
        handleServerResponse(false, error);
        console.log(error);
      });
  };
  useEffect(() => {
    console.log('CALL FORM');
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          email: '',
          fullname: '',
          company: '',
          jobtitle: '',
          jobposition: '',
          industry: '',
          location: '',
          request: '',
          message: '',
        }}
        onSubmit={handleOnSubmit}
        validationSchema={formSchema}
      >
        {({ isSubmitting }) => (
          <Form id="fs-frm" noValidate css={formStyles} autoComplete="off">
            <Field name="email" id="email" type="email" component={Input} />
            <Field
              name="fullname"
              id="fullname"
              type="text"
              component={Input}
            />
            <Field name="company" id="company" type="text" component={Input} />
            <Field
              name="jobtitle"
              id="jobtitle"
              type="text"
              component={Input}
            />
            <Field name="jobposition" id="jobposition" component={Select}>
              <option value=""></option>
              {FORM_DATA.jobPosition.map((option, index) => (
                <option value={option} key={option + '-' + index}>
                  {option}
                </option>
              ))}
            </Field>
            <Field name="industry" id="industry" component={Select}>
              <option value=""></option>
              {FORM_DATA.industry.map((option, index) => (
                <option value={option} key={option + '-' + index}>
                  {option}
                </option>
              ))}
            </Field>

            <Field
              name="location"
              id="location"
              type="text"
              component={Input}
            />
            <Field name="request" id="request" component={Select}>
              <option value=""></option>
              {FORM_DATA.request.map((option, index) => (
                <option value={option} key={option + '-' + index}>
                  {option}
                </option>
              ))}
            </Field>
            <Field
              name="message"
              id="message"
              component={TextArea}
              rows="8"
              cols="1"
            />

            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
            {serverState && (
              <p className={!serverState.ok ? 'errorMsg' : ''}>
                {serverState.msg}
              </p>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;

//components
const Input = ({ field, meta, form: { touched, errors }, ...props }) => {
  return (
    <div className="field-wrapper">
      <input
        id={props.id}
        type={props.type}
        className={field.value !== '' ? 'active' : null}
        {...field}
        {...props}
      />

      <label htmlFor={props.id}>{field.name}</label>
      {touched && errors && (
        <ErrorMessage name={field.name} className="errorMsg" component="p" />
      )}
    </div>
  );
};

const Select = ({ field, meta, form: { touched, errors }, ...props }) => {
  return (
    <div className="field-wrapper">
      <select
        id={props.id}
        type={props.type}
        className={field.value !== '' ? 'active' : null}
        {...field}
        {...props}
      />
      {touched && errors && (
        <ErrorMessage name={field.name} className="errorMsg" component="p" />
      )}
      <label htmlFor={props.id}>{field.name}</label>
    </div>
  );
};
const TextArea = ({ field, meta, form: { touched, errors }, ...props }) => {
  return (
    <div className={`field-wrapper textarea`}>
      <textarea
        id={props.id}
        type={props.type}
        className={field.value !== '' ? 'active' : null}
        {...field}
        {...props}
      />

      <label htmlFor={props.id}>{field.name}</label>
      {touched && errors && (
        <ErrorMessage name={field.name} className="errorMsg" component="p" />
      )}
    </div>
  );
};

//styles
const formStyles = css`
  display: block;

  @media (min-width: 768px) {
    display: grid;
    column-gap: 24px;
    grid-template-columns: 1fr 1fr;
  }

  > * {
    width: 100%;
    font-size: 16px;
  }
  .field-wrapper {
    position: relative;
    margin-bottom: 32px;
    &.textarea {
      margin-top: 72px;
      grid-column: 1 / span 2;
      @media (min-width: 768px) {
        margin-top: 32px;
      }
    }

    @media (min-width: 768px) {
      margin-bottom: 40px;
    }
  }
  label {
    display: block;
    text-transform: uppercase;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    transition: top 300ms ease;
    pointer-events: none;
  }
  input,
  select {
    display: block;
    min-height: 40px;
    width: 100%;
    padding: 0;
    font-size: 100%;
    margin-top: 4px;
    color: white;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    &.active,
    &.focus-visible {
      outline: none;

      & + label {
        transform: translateY(-80%);
        top: 0;
        font-size: 12px;
      }
    }
  }
  textarea {
    resize: vertical;
    display: block;
    min-height: 40px;
    width: 100%;
    padding: 8px;
    font-size: 100%;

    color: white;
    background-color: transparent;
    border: none;
    border: 1px solid white;
    & + label {
      top: -16px;
    }
    &.active,
    &.focus-visible {
      outline: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-bottom: 1px solid white;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
  button {
    display: block;
    min-height: 40px;
    background: white;
    border: none;
    font-size: 100%;
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
    grid-column: 1 / span 2;
  }
  .errorMsg {
    position: absolute;
    right: 0;
    bottom: 12px;
    color: red;
    font-size: 12px;
  }
  select {
    & + .errorMsg {
      right: 24px;
    }
  }
`;

const FORM_DATA = {
  jobPosition: [
    'C-Level Executive',
    'Manager',
    'Executive / Coordinator',
    'Assistant / Entry Level',
    'Student',
    'Entrepreneur / Sole Proprietor',
    'Other',
  ],
  industry: [
    'Advertising & Marketing',
    'Apparel & Fashion',
    'Automotive',
    'Beauty, Cosmetics & Perfumes',
    'Consulting & Business Services',
    'Consumer Electronics',
    'Digital & Ecommerce',
    'Education',
    'Financial Services',
    'FMCG',
    'Food & Beverages',
    'Government',
    'Health, Wellness & Fitness',
    'Industrial Goods',
    'IT',
    'Luxury Goods & Services (Non-Apparel & Fashion)',
    'Media and Entertainment',
    'Non-profit',
    'Research',
    'Retail',
    'Travel & Leisure',
    'Watches & Jewellry',
    'Other',
  ],
  request: [
    'Shop Customer Care',
    'Advertising / Sales',
    'Legal',
    // 'Jobs',
    'Editorial',
    'General',
    'Technical Issues',
  ],
};
