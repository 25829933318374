import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import Icon from './icon';

const Modal = ({ handleCloseModal, show, children }) => {
  useEffect(() => {
    if (show) {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [show]);

  const modalClass = css`
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.9);
    display: ${show ? 'flex' : 'none'};
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    margin-bottom: 32px;
    @media (min-width: 768px) {
      align-items: center;
      top: 0;
    }

    .modal-main {
      padding: 96px 16px;
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      flex-direction: column-reverse;
      opacity: 1;
      position: absolute;
      top: 0;
      /* transform: translateY(100%); */
      transition: opacity 3000ms ease-in-out;

      &.visible {
        opacity: 1;
        /* transform: translateY(0%); */
      }
      @media (min-width: 768px) {
        padding-top: 128px;
        width: 800px;
        flex-direction: row;
        align-items: flex-start;
      }
      .modal-content {
        padding: 16px;
        width: 100%;
        background-color: black;
        border: 1px solid white;
        @media (min-width: 768px) {
          padding: 32px;
        }
      }
      .modal-closeBtn {
        background: transparent;
        padding: 8px;
        border: none;
        transform: translate(12px, -12px);
        cursor: pointer;
      }

      svg {
        stroke: white;
      }
    }
  `;

  return (
    <div css={modalClass}>
      <section className={show ? 'modal-main visible' : 'modal-main'}>
        <div className="modal-content">{children}</div>
        <button className="modal-closeBtn" onClick={handleCloseModal}>
          <Icon name="close" />
        </button>
      </section>
    </div>
  );
};

export default Modal;
